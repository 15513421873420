import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';


export default class extends Controller {
  static targets = ["map", "inspector", "inspectorBody"]
  
  connect() {
    if (typeof ymaps !== 'undefined') {
      ymaps.ready(this.init.bind(this))
    } else {
      console.error("Yandex Maps API not loaded")
    }
  }

  init() {
    this.map = new ymaps.Map(this.mapTarget, {
      center: [43.2220, 76.8512],
      zoom: 12,
      controls: ["zoomControl", "geolocationControl", "rulerControl"]
    });

    this.objectManager = new ymaps.ObjectManager({
      clusterize: true,
      geoObjectIconImageHref: "/BALOON.svg",
      geoObjectIconLayout: "default#image",
      geoObjectIconImageSize: [40, 44],
      preset: 'islands#invertedRedClusterIcons'
    });

    this.map.geoObjects.add(this.objectManager);

    this.objectManager.objects.events.add(["click"], (e) => {
      const object = e.get("objectId");
      this.inspectSchool(object);
    });

    // Initialize select2 on page load
    this.initializeKatoSelects();

    // Trigger initial load
    document.querySelector('#school_kato_level_2_id').dispatchEvent(new Event('change'));
    document.querySelector('.filters .apply').click();
  }

  closeInspector(event) {
    this.inspectorTarget.style.display = 'none';
  }

  dismissInspector(event) {
    this.inspectorTarget.style.display = 'none';
    this.inspectorBodyTarget.innerHTML = "";
  }

  inspectSchool(schoolId) {
    fetch(`/${this.locale}/map/schools/${schoolId}`)
      .then(response => response.text())
      .then(html => {
        this.inspectorTarget.style.display = 'block';
        this.inspectorBodyTarget.innerHTML = html;
      });
  }

  applyFilters(event) {
    const params = {};
    
    for (let i = 2; i < 7; i++) {
      const select = document.querySelector(`#school_kato_level_${i}_id`);
      if (select && select.value !== "") {
        params.kato_geo_object_id = select.value;
      }
    }

    const queryString = new URLSearchParams(params).toString();
    
    fetch(`/ajax/schools/bbox?${queryString}`)
      .then(response => response.json())
      .then(data => {
        if (data != null && data.schools != null) {
          this.objectManager.removeAll();
          this.objectManager.add(data.schools);
          this.map.geoObjects.removeAll();
          this.map.geoObjects.add(this.objectManager);
          this.map.setBounds(data.bbox);
        } else {
          alert("Для этого региона нет школ в базе данных");
        }
      });
  }

  initializeKatoSelects() {
    this.initializeKatoSelect(2, true, false);
    this.initializeKatoSelect(3, false, false);
    this.initializeKatoSelect(4, false, false);
    this.initializeKatoSelect(5, false, false);
    this.initializeKatoSelect(6, false, true);
  }

  initializeKatoSelect(level, isRoot, isFinal) {
    const selectId = `#school_kato_level_${level}_id`;
    const select = document.querySelector(selectId);
    
    // Initialize select2
    $(select).select2({
      ajax: {
        url: (params) => {
          if (isRoot) {
            return `/${this.locale}/ajax/geo_entities/kato?level=${level}`;
          } else {
            const parentSelect = document.querySelector(`#school_kato_level_${level-1}_id`);
            if (parentSelect && parentSelect.value) {
              return `/${this.locale}/ajax/geo_entities/kato?parent_id=${parentSelect.value}`;
            }
            return `/${this.locale}/ajax/geo_entities/kato?level=${level}`;
          }
        },
        dataType: 'json'
      },
      placeholder: "-",
      allowClear: true,
      height: '64px',
      width: '100%'
    });

    if (!isFinal) {
      $(select).on('change', () => {
        const nextSelect = document.querySelector(`#school_kato_level_${level+1}_id`);
        const nextFilter = nextSelect ? nextSelect.closest('.filter') : null;
        
        if (nextSelect) {
          $(nextSelect).val("");
          $(nextSelect).trigger('change');
        }

        if (nextFilter) {
          if (select.value === "" || select.value === null) {
            nextFilter.style.display = 'none';
          } else {
            nextFilter.style.display = 'block';
          }
        }
      });

      // Initial visibility
      const nextSelect = document.querySelector(`#school_kato_level_${level+1}_id`);
      const nextFilter = nextSelect ? nextSelect.closest('.filter') : null;
      
      if (nextFilter && (select.value === "" || select.value === null)) {
        nextFilter.style.display = 'none';
      }
    }
  }

  get locale() {
    return document.documentElement.lang || 'ru';
  }
}